import React, { useState } from "react";
import { useVolcano, VolcanoClientProvider, VolcanoClientStatus } from "volcano-client-library";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import ProgressBar from "../../components/progress-bar/ProgressBar";
import Spinner from "../../components/spinner/Spinner";
import Blocker from "../../components/blocker/Blocker";
import { useWallet } from "@solana/wallet-adapter-react";
import { MintButton } from "../../components/buttons/MintButton";
import TotalMinted from "../../components/collection/total-minted/TotalMinted";
import TotalItems from "../../components/collection/total-items/TotalItems";
import MintPrice from "../../components/collection/mint-price/MintPrice";
import Popup from "../../components/popup/Popup";
import MintStart from "../../components/collection/mint-start/MintStart";
import WhitelistStart from "../../components/collection/whitelist-start/WhitelistStart";
import { API } from "../../core/consts";

export default function Maceo(){
  const { publicKey } = useWallet();
	return (
    <VolcanoClientProvider
			api={API}
			collection={new PublicKey("2nSpjpvJVYM66bAjVwgcYeWsLw8qMDBwunsWDZD2Lo7Y")}
			payer={publicKey}
		>
			<PageRenderer />
		</VolcanoClientProvider>
  );
};

function PageRenderer(){
	const { collectionData } = useVolcano();
	const [message, setMessage] = useState(null);
	const offset = 111;

	if(collectionData){
		return (
			<div className="root">
				<div className="container">
					<div className="inner-containers left-section">
						<div className="title-section">
							<h1>SHADES<br/>w/ Maceo Plex</h1>
							<div className="total-price">
								<p>Number of items: <TotalItems offset={offset} /></p>
								<p>Mint price: <MintPrice /> sol</p>
								<p>Mint starts: <strong><MintStart format={{month:'long', day:'2-digit', timeZone: 'UTC'}} /></strong></p>
							</div>
						</div>
						<div className="description">
						Dive into the limitless dimension with one of the biggest acts on the electronic scene, Maceo Plex, and see the Virtual Universe through his eyes with these spectacular shades.
						<br/>
						Exclusive EXIT NFTs will open you the doors of extraordinary real-life experiences that only a few have come across so far!
						<br/>
						<b>After the mint, the collection will be tradable on the official <a href="https://solsea.io/collection/62acb2d3895f70e1cb3528db" target="_blank">EXIT Shades w/ Maceo Plex</a> SolSea page.</b>
						</div>
						<div className="total-price">
							<p>
								Whitelist mint starts on <WhitelistStart format={{month:'long', day:'2-digit', timeZone: 'UTC'}} />, at <strong><WhitelistStart format={{hour:'numeric', timeZone: 'UTC', timeZoneName:'short'}} /></strong>
							</p>
							<p>
								Public mint starts on <MintStart format={{month:'long', day:'2-digit', timeZone: 'UTC'}} />, at <strong><MintStart format={{hour:'numeric', timeZone: 'UTC', timeZoneName:'short'}} /></strong>
							</p>
						</div>
						<div className="mint-container">
							<MintButton messagesHandler={(msg)=>{
								setMessage(msg);
							}} />
							<ProgressBar completed={ (collectionData.minted.toNumber() + offset) / (collectionData.supply.toNumber() + offset) * 100 } />
						</div>
						<div className="progress-container">
							<TotalMinted offset={offset}/>
						</div>
						<div className="collection-button">
							<a href="https://nft.exitfest.org/collections/shades/" target="_blank">View collection</a>
						</div>
					</div>
					<div className="inner-containers">
						<img
							className="carmela-maceo-image"
							src="/assets/maceo.jpg"
							alt="maceo"
						/>
					</div>
				</div>
				{
					message &&
						<Blocker>
							<Popup message={message} close={()=>{setMessage(null)}}/>
						</Blocker>
				}
			</div>
		);
	}

	return (
		<Blocker>
			<Spinner />
		</Blocker>
	);
}
