import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

const Home = () => {
  return (
    <div className="homepage">
      <div className="ready-class">
        {/* <h1>Ready?</h1>
        <h2>Choose your minter:</h2> */}
				<h1>EXIT NFT Collections</h1>
      </div>
      <div className="base-button">

        <div className="minter">
					<div className="minter-image">
						<img src="/assets/christina_icon.png" alt="Carmela/" title="Carmela"/>
					</div>
					<div className="minter-title">
						<h2><b>Wings</b><br/>w/ Christina Carmela</h2>
					</div>
					<div className="minter-about">
						Fly through incredible live experiences into the Metaverse on the majestic wings of pilot, model and women rights activist Christina Carmela.​
					</div>
					<Link to="/carmela">
						<div className="link-button"><p>WINGS</p></div>
					</Link>
				</div>

				<div className="minter">
					<div className="minter-image">
						<img src="/assets/maceo_icon.png" alt="Carmela/" title="Carmela"/>
					</div>
					<div className="minter-title">
						<h2><b>Shades</b><br/>w/ Maceo Plex</h2>
					</div>
					<div className="minter-about">
						Dive into the limitless dimension with one of the biggest acts on the electronic scene, Maceo Plex, and see the Virtual Universe through his eyes with these spectacular shades.​
					</div>
					<Link to="/maceo">
						<div className="link-button"><p>SHADES</p></div>
					</Link>
				</div>

      </div>
    </div>
  );
};

export default Home;
