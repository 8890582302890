import React, { useMemo } from "react";
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl, PublicKey } from '@solana/web3.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Carmela from "./pages/carmela/Carmela";
import Home from "./pages/home/Home";
import Maceo from "./pages/maceo/Maceo";
import Header from "./components/header/Header";
import { VolcanoClientProvider } from 'volcano-client-library';
import { API } from "./core/consts";

require('@solana/wallet-adapter-react-ui/styles.css');

export default function App() {
	const network = WalletAdapterNetwork.Mainnet;
	const endpoint = useMemo(() => clusterApiUrl(network), [network]);
	const wallets = useMemo(
		() => [
			new PhantomWalletAdapter(),
			new SolflareWalletAdapter({ network }),
		],
		[network]
	);

	return (
		<div className="app">
			<Router>
				<ConnectionProvider endpoint={endpoint}>
					<WalletProvider wallets={wallets} autoConnect>
						<WalletModalProvider>
							<Header/>
								<Routes>
									<Route path="/" element={<Home />} />
									<Route
										path="/maceo"
										element={<Maceo />}
									/>
									<Route path="/carmela" element={<Carmela />} />
								</Routes>
						</WalletModalProvider>
					</WalletProvider>
				</ConnectionProvider>
			</Router>
		</div>
	);
}
