import React from "react";
import {} from 'volcano-client-library'
import "./ProgressBar.css";

const ProgressBar = ({ bgcolor, completed }) => {
  const fillerStyles = {
    width: `${round(completed, 1)}%`
  };

  return (
    <div className="container-styles">
      <div style={fillerStyles} className="filler-styles"></div>
			<div className="label-wrapper">
				<span className="label-styles">{`${round(completed, 1)}%`}</span>
			</div>
    </div>
  );
};

function round(number, decimals) {
	const factor = Math.pow(10, decimals);
	return Math.round(number * factor) / factor;
}

export default ProgressBar;
