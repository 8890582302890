import React from "react";
import { useVolcano } from "volcano-client-library";

const options = {
	timeZone: 'UTC',
	weekday: 'long',
	year: 'numeric',
	month: 'long',
	day: '2-digit'
};

export default function WhitelistStart({format, className}){

	const { collectionData } = useVolcano();
	const date = new Date(
		(collectionData.whitelistConfig)
		? collectionData.whitelistConfig.start.toNumber()
		:collectionData.launchStart.toNumber()
	);
	const dateFormat = (format) ? {...format} : {...options};
	return (
		<span>{`${date.toLocaleString('en-US', dateFormat)}`}</span>
	);
}
