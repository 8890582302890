import React, { useEffect, useState } from "react";
import { useVolcano, VolcanoClientProvider, VolcanoClientStatus } from "volcano-client-library";
import { LAMPORTS_PER_SOL, PublicKey, Transaction } from "@solana/web3.js";
import ProgressBar from "../../components/progress-bar/ProgressBar";
import Spinner from "../../components/spinner/Spinner";
import Blocker from "../../components/blocker/Blocker";
import { useWallet } from "@solana/wallet-adapter-react";
import { MintButton } from "../../components/buttons/MintButton";
import Popup from "../../components/popup/Popup";
import TotalItems from "../../components/collection/total-items/TotalItems";
import MintPrice from "../../components/collection/mint-price/MintPrice";
import TotalMinted from "../../components/collection/total-minted/TotalMinted";
import MintStart from "../../components/collection/mint-start/MintStart";
import WhitelistStart from "../../components/collection/whitelist-start/WhitelistStart";
import { API } from "../../core/consts";

export default function Carmela(){
	const { publicKey } = useWallet();
	return (
		<VolcanoClientProvider
			api={API}
			collection={new PublicKey("3gmUp715rPiwUtpsuHJTm8aNdzhJCcXYSQfPqfR6AKYj")}
			payer={publicKey}
		>
			<PageRenderer />
		</VolcanoClientProvider>
	);
};

function PageRenderer(){
	const { collectionData } = useVolcano();
	const [message, setMessage] = useState(null);

	if(collectionData){
		return (
			<div className="root">
				<div className="container">
					<div className="inner-containers left-section">
						<div className="title-section">
							<h1>WINGS <br/>w/ Christina Carmela </h1>
							<div className="total-price">
								<p>Number of items: <TotalItems /></p>
								<p>Mint price: <MintPrice /> sol</p>
								<p>Mint starts: <strong><MintStart format={{month:'long', day:'2-digit', timeZone: 'UTC'}} /></strong></p>
							</div>
						</div>
						<div className="description">
						Fly through incredible live experiences into the Metaverse on the majestic wings of pilot, model and women rights activist Christina Carmela.
						<br/>
						Exclusive EXIT NFTs will open you the doors of extraordinary real-life experiences that only a few have come across so far!
						<br/>
						<b>After the mint, the collection will be tradable on the official <a href="https://solsea.io/collection/62acb171895f70e1cb3528d3" target="_blank">EXIT Wings w/ Christina Carmela</a> SolSea page.</b>
						</div>
						<div className="total-price">
							<p>
								Whitelist mint starts on <WhitelistStart format={{month:'long', day:'2-digit', timeZone: 'UTC'}} />, at <strong><WhitelistStart format={{hour:'numeric', timeZone: 'UTC', timeZoneName:'short'}} /></strong>
							</p>
							<p>
								Public mint starts on <MintStart format={{month:'long', day:'2-digit', timeZone: 'UTC'}} />, at <strong><MintStart format={{hour:'numeric', timeZone: 'UTC', timeZoneName:'short'}} /></strong>
							</p>
						</div>
						<div className="mint-container">
							<MintButton messagesHandler={(msg)=>{
								setMessage(msg);
							}} />
							<ProgressBar completed={ collectionData.minted.toNumber() / collectionData.supply.toNumber() * 100 } />
						</div>
						<div className="progress-container">
							<TotalMinted/>
						</div>
						<div className="collection-button">
							<a href="https://nft.exitfest.org/collections/wings/" target="_blank">View collection</a>
						</div>
					</div>
					<div className="inner-containers">
						<img
							className="carmela-maceo-image"
							src="/assets/carmela22.jpg"
							alt="carmela"
						/>
					</div>
				</div>
				{
					message &&
						<Blocker>
							<Popup message={message} close={()=>{setMessage(null)}}/>
						</Blocker>
				}
			</div>
		);
	}

	return (
		<Blocker>
			<Spinner />
		</Blocker>
	);
}
