import React from "react";
import { useVolcano } from "volcano-client-library";

export default function TotalMinted({offset}){
	const { collectionData, status } = useVolcano();
	const _offset = (offset) ? offset : 0;
	return (
		<div className="total-minted">Total minted: {`${collectionData.minted.toNumber() + _offset}/${collectionData.supply.toNumber() + _offset}`}</div>
	);
}
