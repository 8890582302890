import { useWallet } from "@solana/wallet-adapter-react";
import { useState } from "react";
import { useVolcano, VolcanoClientStatus } from "volcano-client-library";
import Spinner from "../spinner/Spinner";
import './mint-button.css';
export function MintButton({messagesHandler}){

	const { status, collection, getTransaction, sendTransaction, collectionData } = useVolcano();
	const { connected, connect, signTransaction, publicKey,  } = useWallet();
	const [isMinting, setIsMinting] = useState(false);

	const dispatchMessage = (message) => {
		if(messagesHandler)
			messagesHandler(message);
	};

	const mint = async () => {
		try{
			if(isMinting || status !== VolcanoClientStatus.Idle)
				return;

			setIsMinting(true);
			if(!publicKey){
				setIsMinting(false);
				dispatchMessage("Please select wallet first.");
				return;
			}
			if(status !== VolcanoClientStatus.Idle){
				setIsMinting(false);
				return;
			}

			const tx = await getTransaction();
			if(tx.err){
				dispatchMessage(tx.err.message);
				setIsMinting(false);
				return;
			}

			const signed = await signTransaction(tx.transaction);
			if(signed){
				const mintResult = await sendTransaction(signed, tx.blockHeight);
				if(mintResult.err){
					dispatchMessage(mintResult.err.message);
					setIsMinting(false);
					return;
				}
				dispatchMessage("Your NFT is successfully minted.<br/>Check your wallet...");
			}
			else{
				dispatchMessage("Unable to sign your transaction.");
			}
			setIsMinting(false);
		}
		catch(err){
			setIsMinting(false);
			dispatchMessage(err.message);
		}
	}

	return (
		<button
			className={
				(isMinting)
				? "mint-button mint-button-disabled"
				: "mint-button"
			}
			onClick={mint}
		>
			{
				(isMinting)
				? <Spinner />
				: <span>MINT</span>
			}
		</button>
	);
}
