import React from "react";
import { useVolcano } from "volcano-client-library";

export default function TotalItems({offset}){
	const { collectionData } = useVolcano();
	const _offset = (offset) ? offset : 0;
	return (
		<span><strong>{`${collectionData.supply.toNumber() + _offset}`}</strong></span>
	);
}
