import React from "react";
import "./Popup.css";

const Popup = (props)=>{
	const { close, message } = props;
	return(
		<div className="popup">
			<button className="close" onClick={close}>
			<svg width="20" height="21" viewBox="0 0 20 21" fill="#C20272" xmlns="http://www.w3.org/2000/svg">
				<path d="M11.4695 10.0041L19.7595 1.71409C19.9234 1.52279 20.009 1.27671 19.9993 1.02504C19.9895 0.773362 19.8852 0.534623 19.7071 0.356529C19.529 0.178434 19.2903 0.0741013 19.0386 0.0643802C18.7869 0.054659 18.5408 0.140266 18.3495 0.304092L10.0595 8.59409L1.76954 0.294092C1.58124 0.105788 1.32585 0 1.05954 0C0.793242 0 0.537847 0.105788 0.349543 0.294092C0.16124 0.482395 0.0554518 0.73779 0.0554518 1.00409C0.0554518 1.27039 0.16124 1.52579 0.349543 1.71409L8.64954 10.0041L0.349543 18.2941C0.244862 18.3837 0.159841 18.4941 0.0998181 18.6181C0.0397948 18.7422 0.00606468 18.8773 0.000745191 19.015C-0.0045743 19.1528 0.0186312 19.2901 0.0689057 19.4184C0.11918 19.5467 0.195439 19.6633 0.292893 19.7607C0.390348 19.8582 0.506895 19.9345 0.63522 19.9847C0.763546 20.035 0.900878 20.0582 1.0386 20.0529C1.17632 20.0476 1.31145 20.0138 1.43551 19.9538C1.55958 19.8938 1.6699 19.8088 1.75954 19.7041L10.0595 11.4141L18.3495 19.7041C18.5408 19.8679 18.7869 19.9535 19.0386 19.9438C19.2903 19.9341 19.529 19.8298 19.7071 19.6517C19.8852 19.4736 19.9895 19.2348 19.9993 18.9831C20.009 18.7315 19.9234 18.4854 19.7595 18.2941L11.4695 10.0041Z" />
			</svg>
			</button>
			<div className="body">
				<span dangerouslySetInnerHTML={{__html:message}} ></span>
			</div>
			<button className="ok" onClick={close}>OK</button>
		</div>
	)
}

export default Popup;
