import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import React from "react";
import { Link } from "react-router-dom";
import "./header.css";

export default function Header(){
	return (
		<header>
			<div className="header-logo">
				{/* <a
					href="https://www.exitfest.org/en"
					rel="noreferrer"
					target="_blank"
				>
					<img src="/assets/exit.png" alt="exit" />
				</a> */}
				<Link to="/">
					<img src="/assets/exit.png" alt="exit" />
				</Link>
			</div>
			<WalletMultiButton />
		</header>
	);
}