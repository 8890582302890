import React from "react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useVolcano } from "volcano-client-library";

export default function MintPrice(){
	const { collectionData } = useVolcano();
	return (
		<span><strong>{`${collectionData.price.toNumber() / LAMPORTS_PER_SOL}`}</strong></span>
	);
}
